export default function requestUrlGenerator(
    indicator: string,
    pageNumber?: number,
    searchTextInput?: string,
    movieId?: number
) {
    const _key = '2de9d8a17c0d3945d79c65f88d7cac21';
    const popular = `https://api.themoviedb.org/3/movie/popular?api_key=`,
        upcoming = `https://api.themoviedb.org/3/movie/upcoming?api_key=`,
        topRater = `https://api.themoviedb.org/3/movie/top_rated?api_key=`,
        search = `https://api.themoviedb.org/3/search/movie?api_key=`,
        movieIdPath = `https://api.themoviedb.org/3/movie/${movieId}?api_key=`,
        lastUrl = `&language=en-US&page=`,
        searchText = '&include_adult=false&query=';
    switch (indicator) {
        case 'popular':
            return `${popular}${_key}${lastUrl}${pageNumber}`;
        case 'upcoming':
            return `${upcoming}${_key}${lastUrl}${pageNumber}`;
        case 'topRater':
            return `${topRater}${_key}${lastUrl}${pageNumber}`;
        case 'search':
            return `${search}${_key}${lastUrl}${pageNumber}${searchText}${searchTextInput}`;
        case 'searchId':
            return `${movieIdPath}${_key}&language=en-US`;
        default:
            return `${popular}${_key}${lastUrl}${pageNumber}`;
    }
}
