interface IResData {
    adult: boolean;
    backdrop_path: string;
    genre_ids: number[];
    id: number;
    original_language: string;
    original_title: string;
    overview: string;
    popularity: number;
    poster_path: string;
    release_date: string;
    title: string;
    video: boolean;
    vote_average: number;
    vote_count: number;
};

interface IGenres {
    id: number;
    name: string;
};

interface IProductionCompanies {
    id: number;
    logo_path: string;
    name: string;
    origin_country: string;
};

interface IProductionCountries {
    iso_3166_1: string;
    name: string;
};

interface ISpokenLanguages {
    english_name: string;
    iso_639_1: string;
    name: string;
};

interface IResIdData {
    adult: boolean;
    backdrop_path: string;
    belongs_to_collection: null;
    budget: number;
    genres: IGenres[];
    homepage: string;
    id: number;
    imdb_id: string;
    original_language: string;
    original_title: string;
    overview: string;
    popularity: number;
    poster_path: string;
    production_companies: IProductionCompanies[];
    production_countries: IProductionCountries[];
    release_date: string;
    revenue: number;
    runtime: number;
    spoken_languages: ISpokenLanguages[];
    status: string;
    tagline: string;
    title: string;
    video: boolean;
    vote_average: number;
    vote_count: number;
};

export interface IMovie {
    id: number;
    overview: string;
    releaseDate: string;
    imgUrl: string;
    name: string;
    bgImg: string;
};

export default async function request(
    url: string,
    indicator?: string
): Promise<IMovie[]> {
    const data = await fetch(url);
    const dataMovies = await data.json();

    if (indicator === 'searchId') {
        const dataId: IMovie[] = [dataMovies].map(
            (item: IResIdData): IMovie => {
                return {
                    id: item.id,
                    overview: item.overview,
                    releaseDate: item.release_date,
                    imgUrl: item.poster_path,
                    name: item.original_title,
                    bgImg: item.backdrop_path,
                };
            }
        );
        return dataId;
    } else {
        const dataFilter: IMovie[] = dataMovies.results.map(
            (item: IResData): IMovie => {
                return {
                    id: item.id,
                    overview: item.overview,
                    releaseDate: item.release_date,
                    imgUrl: item.poster_path,
                    name: item.original_title,
                    bgImg: item.backdrop_path,
                };
            }
        );
        return dataFilter;
    }
}
