import request from './request';
import renderMovieCard from './renderMovieCard';
import { IMovie } from './request';
import filterMovies from './filterMovie';
import likeToggle from './likesToggle';
import renderLikesList from './renderLikesList';
import renderRandomMovies from './renderRandomMovie';

export async function render(): Promise<void> {
    let data: IMovie[];
    data = (await request(
        'https://api.themoviedb.org/3/movie/popular?api_key=2de9d8a17c0d3945d79c65f88d7cac21&language=en-US&page=1'
    )) as IMovie[];

    if (localStorage.getItem('likesList') === null) {
        localStorage.setItem('likesList', JSON.stringify([]));
    }
    const parent = document.querySelector('.parent_cards') as HTMLDivElement;

    data.forEach((item) => {
        const { id, overview, releaseDate, imgUrl } = item;
        parent.append(renderMovieCard(imgUrl, overview, releaseDate, id));
    });
    renderRandomMovies();
    likeToggle();
    filterMovies(data, parent);
    renderLikesList();
}
