import request from "./request";
import requestUrlGenerator from "./requestUrlGenerator";
import { randomMovie } from "./renderMovieCard";
export default async function renderRandomMovies() {
    const data = await request(requestUrlGenerator('popular', 1));
    function randomInteger(min: number, max: number): number {
        let rand: number = min + Math.random() * (max + 1 - min);
        return Math.floor(rand);
      }
      const {bgImg, overview, name} = data[randomInteger(0, 19)];
      randomMovie(bgImg, overview, name);
}