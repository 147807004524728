import { cardId } from './renderMovieCard';
import request from './request';
import requestUrlGenerator from './requestUrlGenerator';

export default function renderLikesList(): void {
    const parent = document.querySelector(
        '.parent_for_likes_cards'
    ) as HTMLDivElement;
    const cardsData: number[] = JSON.parse(
        localStorage.getItem('likesList') as string
    );
    parent.innerHTML = '';
    cardsData.forEach(async (movieId) => {
        const data = await request(
            requestUrlGenerator('searchId', 0, '', movieId),
            'searchId'
        );
        data.forEach((item) => {
            const { id, overview, releaseDate, imgUrl } = item;
            parent.append(cardId(imgUrl, overview, releaseDate, id));
        });
    });
    changeLike();
}
export function changeLike(): void {
    const likesListData: number[] = JSON.parse(
        localStorage.getItem('likesList') as string
    );
    document.querySelectorAll('svg').forEach((svg) => {
        const status = likesListData.some((item) => item === +svg.id);
        if (status) {
            svg.style.fill = 'red';
        } else {
            svg.style.fill = '#ff000028';
        }
    });
}
