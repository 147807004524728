export default function renderMovieCard(
    url: string,
    cardText: string,
    date: string,
    id: number
): HTMLDivElement {
    if (localStorage.getItem('likesList') === null) {
        localStorage.setItem('likesList', JSON.stringify([]));
    }

    const likesListData: number[] = JSON.parse(
        localStorage.getItem('likesList') as string
    );
    const status = likesListData.some((item) => item === id);

    const firstUrl = `https://image.tmdb.org/t/p/original/${url}`;
    const urlFinish = url ? firstUrl : './src/image/not_found_image.jpg';

    const card = document.createElement('div');
    card.classList.add('col-lg-3');
    card.classList.add('col-md-4');
    card.classList.add('col-12');
    card.classList.add('p-2');
    card.innerHTML = `
    <div class="card shadow-sm">
        <img src="${urlFinish}"/>
        <svg 
            id="${id}"
            xmlns="http://www.w3.org/2000/svg"
            stroke="red"
            fill="${status ? 'red' : '#ff000028'}"
            width="50"
            height="50"
            class="bi bi-heart-fill like position-absolute p-2"
            viewBox="0 -2 18 22"
        >
            <path
                fill-rule="evenodd"
                d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
            />
        </svg>
        <div class="card-body">
            <p class="card-text truncate">
                ${cardText}
            </p>
            <div
                class="
                    d-flex
                    justify-content-between
                    align-items-center
                "
            >
                <small class="text-muted">${date}</small>
            </div>
        </div>
    </div>
    `;
    return card;
}

export function cardId(
    url: string,
    cardText: string,
    date: string,
    id: number
) {
    if (localStorage.getItem('likesList') === null) {
        localStorage.setItem('likesList', JSON.stringify([]));
    }

    const likesListData: number[] = JSON.parse(
        localStorage.getItem('likesList') as string
    );
    const status = likesListData.some((item) => item === id);
    const firstUrl = `https://image.tmdb.org/t/p/original/${url}`;
    const urlFinish = url ? firstUrl : './src/image/not_found_image.jpg';

    const card = document.createElement('div');
    card.innerHTML = `
    <div style="margin-bottom: 20px" class="card shadow-sm">
        <img src="${urlFinish}"/>
        <svg 
            id="${id}"
            xmlns="http://www.w3.org/2000/svg"
            stroke="red"
            fill="${status ? 'red' : '#ff000028'}"
            width="50"
            height="50"
            class="bi bi-heart-fill like position-absolute p-2"
            viewBox="0 -2 18 22"
        >
            <path
                fill-rule="evenodd"
                d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
            />
        </svg>
        <div class="card-body">
            <p class="card-text truncate">
                ${cardText}
            </p>
            <div
                class="
                    d-flex
                    justify-content-between
                    align-items-center
                "
            >
                <small class="text-muted">${date}</small>
            </div>
        </div>
    </div>
    `;
    return card;
}

export function randomMovie (
    url: string,
    cardText: string,
    name: string
    ) {
        const randomMovie = document.querySelector('#random-movie') as HTMLDivElement;
        randomMovie.style.background = `no-repeat center/100% url('https://image.tmdb.org/t/p/original/${url}')`
        randomMovie.innerHTML = `
    <div class="row py-lg-5">
        <div
            class="col-lg-6 col-md-8 mx-auto"
            style="background-color: #252526b5"
        >
            <h1 id="random-movie-name" class="fw-light text-light">${name}</h1>
            <p id="random-movie-description" class="lead text-white">
                ${cardText}
            </p>
        </div>
    </div>
    `;
}
