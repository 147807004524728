import request from './request';
import { IMovie } from './request';
import renderMovieCard from './renderMovieCard';
import requestUrlGenerator from './requestUrlGenerator';

async function setData(
    url: string,
    data: IMovie[],
    parent: HTMLDivElement
): Promise<void> {
    data.splice(0, data.length);
    data = (await request(url)) as IMovie[];
    parent.innerHTML = '';
    data.forEach((item) => {
        const { id, overview, releaseDate, imgUrl } = item;
        parent.append(renderMovieCard(imgUrl, overview, releaseDate, id));
    });
}
async function addingData(
    url: string,
    data: IMovie[],
    parent: HTMLDivElement
): Promise<void> {
    if (data.length === 0) {
        data.push(...((await request(url.slice(0, -1) + '1')) as IMovie[]));
    }
    let newData = (await request(url)) as IMovie[];
    data.push(...newData);
    parent.innerHTML = '';
    data.forEach((item) => {
        const { id, overview, releaseDate, imgUrl } = item;
        parent.append(renderMovieCard(imgUrl, overview, releaseDate, id));
    });
}

async function searchMovie(
    parent: HTMLDivElement,
    data: IMovie[],
    pageSearch: number
): Promise<void> {
    const input = document.querySelector('#search') as HTMLInputElement;
    input.addEventListener('input', async (e) => {
        const buttonMore = document.querySelector(
            '#load-more'
        ) as HTMLButtonElement;
        const target = e.target as HTMLInputElement;

        data = (await request(
            requestUrlGenerator('search', pageSearch, target.value)
        )) as IMovie[];
        parent.innerHTML = '';
        if (data.length === 0) {
            parent.innerHTML =
                '<h1 style="text-align: center">No movies found</h1>';
            buttonMore.textContent = 'Show current filter';
            buttonMore.style.display = 'block';
        } else {
            buttonMore.style.display = 'none';
        }

        data.forEach((item) => {
            const { id, overview, releaseDate, imgUrl } = item;
            parent.append(renderMovieCard(imgUrl, overview, releaseDate, id));
        });
    });
}
export default function filterMovies(
    data: IMovie[],
    parent: HTMLDivElement
): void {
    const inputFilter = document.querySelectorAll(
        '.input_filter'
    ) as NodeListOf<Element>;
    const buttonMore = document.querySelector(
        '#load-more'
    ) as HTMLButtonElement;
    const inputPopular = document.querySelector('#popular') as HTMLInputElement;
    const inputUpcoming = document.querySelector(
        '#upcoming'
    ) as HTMLInputElement;
    const inputTopRater = document.querySelector(
        '#top_rated'
    ) as HTMLInputElement;

    let pagePopular: number = 1,
        pageUpcoming: number = 1,
        pageTopRater: number = 1,
        pageSearch: number = 1;

    searchMovie(parent, data, pageSearch);

    inputFilter.forEach((item) => {
        item.addEventListener('change', async (e) => {
            e.preventDefault();
            const target = e.target as HTMLInputElement;
            buttonMore.style.display = 'block';
            buttonMore.textContent = 'Load more';
            if (target.id === 'popular' && target.checked) {
                pagePopular = 1;
                setData(
                    requestUrlGenerator('popular', pagePopular),
                    data,
                    parent
                );
            } else if (target.id === 'upcoming' && target.checked) {
                pageUpcoming = 1;
                setData(
                    requestUrlGenerator('upcoming', pageUpcoming),
                    data,
                    parent
                );
            } else if (target.id === 'top_rated' && target.checked) {
                pageTopRater = 1;
                setData(
                    requestUrlGenerator('topRater', pageTopRater),
                    data,
                    parent
                );
            }
        });
    });

    buttonMore.addEventListener('click', () => {
        buttonMore.style.display = 'block';
        buttonMore.textContent = 'Load more';
        if (inputPopular.checked) {
            pagePopular += 1;
            addingData(
                requestUrlGenerator('popular', pagePopular),
                data,
                parent
            );
        } else if (inputUpcoming.checked) {
            pageUpcoming += 1;
            addingData(
                requestUrlGenerator('upcoming', pageUpcoming),
                data,
                parent
            );
        } else if (inputTopRater.checked) {
            pageTopRater += 1;
            addingData(
                requestUrlGenerator('topRater', pageTopRater),
                data,
                parent
            );
        }
    });
}
