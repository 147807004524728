import renderLikesList from "./renderLikesList";
export default function likeToggle() {
    document.addEventListener('click', (e: Event) => {
        const target = e.target as SVGPathElement;
        const svgTarget = target.parentElement as HTMLDivElement;
        if (target) {
            if (svgTarget.classList.contains('like')) {
                const oldData: number[] = JSON.parse(
                    localStorage.getItem('likesList') as string
                );
                if (target.parentElement) {
                    if (oldData.some((item) => item === +svgTarget.id)) {
                        const newData = oldData.filter(
                            (item) => item !== +svgTarget.id
                        );
                        localStorage.setItem(
                            'likesList',
                            JSON.stringify(newData)
                        );
                        target.parentElement.style.fill = '#ff000078';
                    } else {
                        target.parentElement.style.fill = 'red';
                        oldData.push(+svgTarget.id);
                        localStorage.setItem(
                            'likesList',
                            JSON.stringify(oldData)
                        );
                    }
                }
                renderLikesList();
            }
        }
    });
}
